import { Pipe, PipeTransform } from '@angular/core';
import { CorrectionsPricingAgreementRespModel } from '@shared/models/corrections/corrections-detail.model';

@Pipe({
  name: 'pricingRuleset'
})
export class PricingRulesetPipe implements PipeTransform {

  transform(value: CorrectionsPricingAgreementRespModel): string {
    return `${value.pricingAgreementNbrTxt} ${value.pricingRulesetNbr}`;
  }

}
