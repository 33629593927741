<div xpoDialog>
  <section class="header-dialog">
    <h1 class="header-dialog__item">
      {{title}}
      <mat-icon matSuffix class="header-dialog__icon" (click)="close()"> close </mat-icon>
    </h1>
  </section>
  <div mat-dialog-content class="message-dialog">
    <p [innerHTML]="message">
    </p>
  </div>
</div>
