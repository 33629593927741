<ng-container *ngIf="readonly; else radioButton">
  <span [id]="id">{{formField.value | yesNoIndicator}}</span>
</ng-container>

<ng-template #radioButton>
  <mat-radio-group [formControl]="formField" class="radio-btn" [id]="id">
    <ng-container *ngFor="let option of yesNoOptions, let i = index">
      <mat-radio-button
        id="{{id}} + {{i}}"
        name="{{name}} + i"
        [value]="option.value">{{ option.viewValue }}</mat-radio-button>
    </ng-container>
  </mat-radio-group>
</ng-template>
