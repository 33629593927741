import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SubCollection } from '@shared/utils/rx/sub-collection';

@Component({
  selector: 'app-time-picker-form',
  templateUrl: './time-picker-form.component.html',
  styleUrls: ['./time-picker-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimePickerFormComponent implements OnChanges, OnDestroy {
  @Input() id = 'timePicker';
  @Input() formField: AbstractControl;
  @Input() readonly = false;

  subs$ = new SubCollection();
  
  constructor(public cdr: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshData();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  private refreshData(): void {
    if (this.readonly) {
      this.subs$.add = this.formField.valueChanges.subscribe(
        () => this.cdr.detectChanges()
      );
    }
  }
}
