import { Pipe, PipeTransform } from '@angular/core';
import { HideWhenZeroPipePropertiesModel } from '@shared/models/corrections/shared/hide-when-zero.model';

@Pipe({
  name: 'hideWhenZero'
})
export class HideWhenZeroPipe implements PipeTransform {

  transform(value: string | number, valueProperties: HideWhenZeroPipePropertiesModel = { isAccessorialCharge: false }): string | number {
    if (value == null) return '';

    const toNumber = typeof value === 'string' ? +value : value;

    if (valueProperties.isAccessorialCharge) {
      return toNumber !== 0 ? value : '';
    } else {
      return toNumber > 0 ? value : '';
    }
  }
}
