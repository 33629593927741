import { Pipe, PipeTransform } from '@angular/core';
import { BillClassEnum, BillClassTextEnum } from '@shared/enums/common-data/bill-class.enum';

@Pipe({
  name: 'billClass'
})
export class BillClassPipe implements PipeTransform {

  transform(value: BillClassEnum): string {
    switch (value) {
      case BillClassEnum.normalMvmt:
        return BillClassTextEnum.normalMvmt;
      case BillClassEnum.masterShpmt:
        return BillClassTextEnum.masterShpmt;
      case BillClassEnum.masterSegment:
        return BillClassTextEnum.masterSegment;
      case BillClassEnum.splitSegment:
        return BillClassTextEnum.splitSegment;
      case BillClassEnum.partialSegment:
        return BillClassTextEnum.partialSegment;
      case BillClassEnum.claimsOvrgRptgBill:
        return BillClassTextEnum.claimsOvrgRptgBill;
      case BillClassEnum.generalClaimsBusShpmt:
        return BillClassTextEnum.generalClaimsBusShpmt;
      case BillClassEnum.astrayFrtSegment:
        return BillClassTextEnum.astrayFrtSegment;
      case BillClassEnum.coBusShpmt:
        return BillClassTextEnum.coBusShpmt;
      case BillClassEnum.expedite:
        return BillClassTextEnum.expedite;
    
      default:
        return '';
    }
  }

}
