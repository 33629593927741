import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TooltipConfigModel } from '@shared/models/corrections/corrections-form/corrections-form.model';

@Component({
  selector: 'app-tooltip-dialog',
  templateUrl: './tooltip-dialog.component.html',
  styleUrls: ['./tooltip-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class TooltipDialogComponent {
  title: string;
  message: string;
  constructor(
    public dialogRef: MatDialogRef<TooltipDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: TooltipConfigModel
  ) {
    this.title = data.title;
    this.message = data.message;
  }

  close(): void {
    this.dialogRef.close();
  }
}
