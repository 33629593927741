<div xpoDialog class="dialog">
    <h1 mat-dialog-title>
        CUSTOMER SEARCH
        <xpo-dialog-close-button></xpo-dialog-close-button>
    </h1>
    <div mat-dialog-content class="dialog__content">
        <xpo-rating-customer-search
            [customerSearchFields]="ratingCustomerSearchFields"
            [customerDisableFields]="customerDisableFields"
            (selectedCustomer$)="selectCustomer($event)"
        ></xpo-rating-customer-search>
    </div>
    <div mat-dialog-actions>
        <button mat-flat-button xpoLargeButton xpoButton (click)="getCustomer()" [disabled]="disableGetCustomerBtn()"
            [id]="getCustomerBtnId">Get Customer</button>
        <button mat-stroked-button xpoLargeButton xpoButton (click)="close()" [id]="cancelBtnId">Cancel</button>
    </div>
</div>