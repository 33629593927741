import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-open-in-new-window-btn',
  templateUrl: './open-in-new-window-btn.component.html',
  styleUrls: ['./open-in-new-window-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenInNewWindowBtnComponent {

  @Input() id = 'newWindowLink';
  @Input() label = 'Open in new Window';

  constructor(private router: Router) { }

  openInNewWindow(): void {
    window.open(this.router.url, '_blank')
  }

}
