import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryQualifierCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'deliveryStatus'
})
export class DeliveryStatusPipe implements PipeTransform {

  transform(deliveryStatus: DeliveryQualifierCd): string | null {
    return !deliveryStatus || deliveryStatus === DeliveryQualifierCd.NOT_APPLICABLE
      ? null : deliveryStatus;
  }

}
