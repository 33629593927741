import { Pipe, PipeTransform } from '@angular/core';
import { ShipmentCreditStatusCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'creditStatus'
})
export class CreditStatusPipe implements PipeTransform {

  transform(creditStatus: ShipmentCreditStatusCd): string {    
    switch (creditStatus) {
      case ShipmentCreditStatusCd.CREDIT:
        return 'CREDIT';

      case ShipmentCreditStatusCd.NON_CREDIT:
        return 'NO CREDIT';
    
      default:
        return '';
    }
  }

}
