import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ratedProcess'
})
export class RatedProcessPipe implements PipeTransform {

  transform(manualRatedInd: boolean): string {
    if (manualRatedInd == null) return '';
    return manualRatedInd ? 'MANUAL' : 'AUTO';
  }

}
