import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-sub-title-form',
  templateUrl: './text-sub-title-form.component.html',
  styleUrls: ['./text-sub-title-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextSubTitleFormComponent {
  @Input() title: string;
}
