import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pendingRequest'
})
export class PendingRequestPipe implements PipeTransform {

  transform(pendingRequestAvailable: number): string {
    return pendingRequestAvailable ? `YES (${pendingRequestAvailable})` : 'NONE';
  }

}
