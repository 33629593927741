import { Pipe, PipeTransform } from '@angular/core';
import { ChargesResponsablePipeModel } from '@shared/models/shared/common-data/charges-responsable.model';
import { ChargeToCd, MatchedPartyTypeCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'chargesResponsable'
})
export class ChargesResponsablePipe implements PipeTransform {

  transform(chargeResponsableData: ChargesResponsablePipeModel): string {
    const value = chargeResponsableData.typeCd ? chargeResponsableData.typeCd : chargeResponsableData.chargeCode;

    switch (value) {
      case MatchedPartyTypeCd.BILL_TO_INB:
      case ChargeToCd.COLL:
        return 'CONSIGNEE <em>PAYS</em>';

      case MatchedPartyTypeCd.BILL_TO_OTB:
      case ChargeToCd.PPD:
      case ChargeToCd.BOTH:
        return 'SHIPPER <em>PAYS</em>';

      default:
        return '';
    }
  }

}
