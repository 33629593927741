<ng-container *ngIf="readonly; else select">
    <span [id]="id">{{formField.value}}</span>
</ng-container>

<ng-template #select>
    <mat-form-field class="select" name="selectField" [appearance]="appearance" [id]="id">
        <mat-label *ngIf="label" class="label">{{label}}</mat-label>
        <mat-select ngDefaultControl [id]="id" [placeholder]="placeholder" (selectionChange)="hazmatChange()"
            [formControl]="formField">
            <mat-option *ngIf="allowEmpty">--</mat-option>
            <mat-option *ngFor="let option of options" [value]="option.value">
                {{ option.viewValue }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>
