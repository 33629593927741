import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSearchComponent implements AfterViewInit {

  @Input() formField: FormControl;
  @Input() readonly = false;
  @Input() focus = false;
  @Input() id = 'inputSearchId';
  @Input() iconSearchId = 'iconSearchId';
  @Input() name = 'inputSearchName';
  @Input() placeholder = '';
  @Input() appearance = 'standard';
  @Input() errorMessage: string | undefined;
  @Input() errorId = 'errorId';

  @Output() enterKey = new EventEmitter<void>();
  @Output() blurAction = new EventEmitter<void>();
  @Output() onSearch = new EventEmitter<void>();

  @ViewChild('inputField') inputField: ElementRef;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.setDefaultFocus();
    this.cdr.detectChanges();
  }

  setDefaultFocus(): void {
    if(!this.focus) return;
    this.inputField.nativeElement.focus();
  }

}
