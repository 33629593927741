import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-corrections-footer',
  templateUrl: './corrections-footer.component.html',
  styleUrls: ['./corrections-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorrectionsFooterComponent {

  constructor() { }

}
