import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SubTitleSeparatorEnum } from '@shared/enums/common-data/sub-title.enum';
import { TextTransformFormatService } from '@shared/services/application-utils/transforms/text/text-transform-format.service';
import { TextFormatType } from '@shared/types/general/text.types';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelComponent implements AfterViewInit {

  @Input() subtitle = '';
  @Input() textFormat: TextFormatType = 'titlecase';
  @Input() id = '';
  @Input() separator: SubTitleSeparatorEnum = SubTitleSeparatorEnum.default;

  @ViewChild('textContent') textContent: ElementRef;

  constructor(
    public cdr: ChangeDetectorRef,
    private textTransformFormatService: TextTransformFormatService
  ) { }

  ngAfterViewInit(): void {
    this.setTextFormat();
  }

  setTextFormat(): void {
    if (this.subtitle) {
      this.subtitle = this.textTransformFormatService.getTitleTransform(this.subtitle as string, this.textFormat);
      this.cdr.detectChanges();
      return;
    }

    this.textContent.nativeElement.textContent =
      this.textTransformFormatService.getTitleTransform(this.textContent.nativeElement.textContent, this.textFormat);
  }

}
