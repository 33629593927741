import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TextAreaComponentModel } from '@shared/models/shared/components/form-fields/text-area-component.model';
import { SubCollection } from '@shared/utils/rx/sub-collection';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextAreaComponent implements OnChanges, OnDestroy {

  @Input() textAreaInput: TextAreaComponentModel = new TextAreaComponentModel();
  @Input() formField: FormControl;
  @Input() readonly = false;

  subs$ = new SubCollection();
  
  constructor(public cdr: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshData();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  private refreshData(): void {
    if (this.readonly) {
      this.subs$.add = this.formField.valueChanges.subscribe(
        () => this.cdr.detectChanges()
      );
    }
  }

}
