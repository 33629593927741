import { Pipe, PipeTransform } from '@angular/core';
import { CorrectionsWeightLineRespModel } from '@shared/models/corrections/corrections-detail.model';

@Pipe({
  name: 'weightLine'
})
export class WeightLinePipe implements PipeTransform {

  transform(weight: CorrectionsWeightLineRespModel | undefined): string {
    if (weight?.asWeight) return `AS WEIGHT`;
    else if(weight?.deficitWeight) return `DFT WEIGHT: ${weight.deficitWeight.description || ''}`;
    else return '';
  }
}
