<div class="sidenav" [@increaseWidth]="openCloseNavBar">
  <div class="sidenav__open-close-btn" [ngClass]="{'sidenav__open-close-btn--open': isOpen}">
    <button mat-icon-button (click)="openCloseAction()" [id]="openCloseBtnId">
      <xpo-icon iconName="arrow-from-left" *ngIf="!isOpen"></xpo-icon>
      <xpo-icon iconName="close" *ngIf="isOpen"></xpo-icon>
    </button>
  </div>
  <ng-container *ngIf="isOpen; else close">
    <ng-content select="[sidenavOpen]"></ng-content>
  </ng-container>
  <ng-template #close>
    <ng-content select="[sidenavClose]"></ng-content>
  </ng-template>
</div>
