import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SectionHeadingButtonModel, SectionHeadingComponentModel } from '@shared/models/shared/components/section-heading/section-heading-component.model';
import { TextTransformFormatService } from '@shared/services/application-utils/transforms/text/text-transform-format.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipDialogComponent } from '../tooltip-dialog/tooltip-dialog.component';
import { TooltipDialogSizeEnum } from '@shared/enums/common-data/tooltip-dialog.enum';
import { TooltipConfigModel } from '@shared/models/corrections/corrections-form/corrections-form.model';

@Component({
  selector: 'app-section-heading',
  templateUrl: './section-heading.component.html',
  styleUrls: ['./section-heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionHeadingComponent implements OnInit {

  @Input() titles: SectionHeadingComponentModel[] = [];
  @Input() buttons: SectionHeadingButtonModel[] = [];

  constructor(
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private textTransformFormatService: TextTransformFormatService
  ) { }

  ngOnInit(): void {
    this.setTextFormat();
  }

  openTooltipDialog(config: TooltipConfigModel): void {
    this.matDialog.open(TooltipDialogComponent, {
      height: TooltipDialogSizeEnum.mdHeight,
      width: TooltipDialogSizeEnum.mdWidth,
      data: config,
    } as MatDialogConfig);
  }

  setTextFormat(): void {
    this.titles = this.titles.map((contentTitle: SectionHeadingComponentModel) => {
      contentTitle.title = this.textTransformFormatService.getTitleTransform(contentTitle.title, contentTitle.textFormat);
      return contentTitle;
    });

    this.cdr.detectChanges();
  }

}
