<ng-container *ngIf="readonly; else datePickerField">
  <span [id]="id">{{formField.value | dateXpo}}</span>
</ng-container>

<ng-template #datePickerField>
  <mat-form-field floatLabel="never" class="date-picker" xpoDatePicker>
      <input matInput [matDatepicker]="datePicker" [id]="id" [name]="name" [formControl]="formField" [min]="minDate">
      <mat-label>{{label}}</mat-label>
      <mat-datepicker-toggle matIconSuffix [for]="datePicker">
          <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
  </mat-form-field>
</ng-template>
