import { Pipe, PipeTransform } from '@angular/core';
import { CountryStatesDataService } from '@shared/services/data/country-states/country-states-data.service';

@Pipe({
  name: 'countryStates'
})
export class CountryStatesPipe implements PipeTransform {

  constructor(private countryStatesDataService: CountryStatesDataService) { }

  transform(abbreviation: string): string {
    const allCountryStates = this.countryStatesDataService.getAllStatesList();
    return allCountryStates.find(countryState => countryState.abbreviation === abbreviation)?.name || '';
  }

}
