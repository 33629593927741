import { MatFormFieldAppearance } from "@angular/material/form-field";
import { TextFormatType } from "@shared/types/general/text.types";

export class TextAreaComponentModel {
    maxCommentsLength: number;
    labelText: string;
    idLabel: string = 'textAreaLabelId'
    idField: string = 'textAreaId';
    nameField: string = 'textAreaName';
    idHint: string = 'textAreaHintId';
    appearance: MatFormFieldAppearance = 'legacy';
    textFormat: TextFormatType = 'titlecase';
    placeholder: string = '';
    rows: number = 4;
    cols: number = 50;
}
