import { LowerCasePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TextFormatType } from '@shared/types/general/text.types';

@Injectable({
  providedIn: 'root'
})
export class TextTransformFormatService {

  constructor(
    private upperCasePipe: UpperCasePipe,
    private titleCasePipe: TitleCasePipe,
    private lowerCasePipe: LowerCasePipe
  ) { }

  getTitleTransform(textValue: string, textFormat: TextFormatType): string {
    if (typeof textValue !== 'string') return textValue;
    
    switch (textFormat) {
      case 'uppercase':
        return this.upperCasePipe.transform(textValue);

      case 'titlecase':
        return this.titleCasePipe.transform(textValue);

      case 'lowercase':
        return this.lowerCasePipe.transform(textValue);
    
      default:
        return textValue;
    }
  }
}
