<ng-container *ngIf="readonly; else textArea">
    <span [id]="textAreaInput.idField">{{formField.value}}</span>
</ng-container>

<app-label
  class="text-area__label"
  [id]="textAreaInput.idLabel"
  *ngIf="textAreaInput.labelText"
  [subtitle]="textAreaInput.labelText"
  [textFormat]="textAreaInput.textFormat">
</app-label>

<ng-template #textArea>
    <mat-form-field floatLabel="never" class="text-area" [appearance]="textAreaInput.appearance">
        <textarea
            matInput
            appInputTextFormat
            [id]="textAreaInput.idField"
            [name]="textAreaInput.nameField"
            [rows]="textAreaInput.rows"
            [cols]="textAreaInput.cols"
            [formControl]="formField"
            [placeholder]="textAreaInput.placeholder"
        ></textarea>
        <mat-hint
            [id]="textAreaInput.idHint"
            *ngIf="textAreaInput.maxCommentsLength"
            [ngClass]="{
                'error': this.formField.errors?.maxlength
            }"
        >
            Max character count: {{ textAreaInput.maxCommentsLength }}, Currently used: {{ formField.value?.length || 0 }}
        </mat-hint>
    </mat-form-field>
</ng-template>
  
