import { Pipe, PipeTransform } from '@angular/core';
import { ChargeToCdAbbrValue } from '@shared/enums/common-data/charge-to-cd.enum';
import { ChargeToCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'chargeCd',
})
export class ChargeCdPipe implements PipeTransform {
  transform(value: ChargeToCd): string {
    switch (value) {
      case ChargeToCd.BOTH:
        return ChargeToCdAbbrValue.both;
      case ChargeToCd.COLL:
        return ChargeToCdAbbrValue.collect;
      case ChargeToCd.PPD:
        return ChargeToCdAbbrValue.prepaid;

      default:
        return '';
    }
  }
}
