import { ElementSelectOptionModel } from "@shared/models/shared/select-option.model";

export const YesNoSelectionTypeValue = {
    yes: true,
    no: false,
};

export const yesNoOptionArrayValue: ElementSelectOptionModel[] = [
    {...new ElementSelectOptionModel(), viewValue: 'YES', value: YesNoSelectionTypeValue.yes},
    {...new ElementSelectOptionModel(), viewValue: 'NO', value: YesNoSelectionTypeValue.no}
];
