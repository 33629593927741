import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SubCollection } from '@shared/utils/rx/sub-collection';

@Component({
  selector: 'app-date-picker-form',
  templateUrl: './date-picker-form.component.html',
  styleUrls: ['./date-picker-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerFormComponent {

  @Input() formField: AbstractControl;
  @Input() id = 'datePicker';
  @Input() name = 'datePickerName';
  @Input() label = 'MM/DD/YYYY';
  @Input() minDate: Date;
  @Input() readonly = false;

  subs$ = new SubCollection();
  
  constructor(public cdr: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshData();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  private refreshData(): void {
    if (this.readonly) {
      this.subs$.add = this.formField.valueChanges.subscribe(
        () => this.cdr.detectChanges()
      );
    }
  }

}
