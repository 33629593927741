import { Pipe, PipeTransform } from '@angular/core';
import {
  CorrectionsWeightFormLabelEnum,
  CorrectionsWeightFormTypeEnum
} from '@shared/enums/corrections/corrections-form/weight/corrections-weight-form.enum';

@Pipe({
  name: 'correctionsFormWeight'
})
export class CorrectionsFormWeightPipe implements PipeTransform {
  constructor() { }

  transform(fieldName: CorrectionsWeightFormTypeEnum): string | undefined {
    switch (fieldName) {
      case CorrectionsWeightFormTypeEnum.deficitWeight:
        return CorrectionsWeightFormLabelEnum.deficitWeight;

      case CorrectionsWeightFormTypeEnum.asWeight:
        return CorrectionsWeightFormLabelEnum.asWeight;
    
      default:
        return undefined;
    }
  }

}
