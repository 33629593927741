import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SideNavHandler } from '@app-store/common-data/side-nav/handler/side-nav.handler';
import { SideNavAnimationStatesEnum, SideNavIdsEnum } from '@shared/enums/common-data/side-nav.enum';
import { SubCollection } from '@shared/utils/rx/sub-collection';
import { take } from 'rxjs/operators';

/**
 * use the sidenavClose directive to display the items that you want to see in the close mode
 * use the sidenavOpen directive to display the items that you want to see in the expanded mode
 */
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('increaseWidth', [
      state(
        SideNavAnimationStatesEnum.close,
        style({
          width: '70px', // Init width
        })
      ),
      state(
        SideNavAnimationStatesEnum.open,
        style({
          width: '350px', // finish width
        })
      ),
      transition(`${SideNavAnimationStatesEnum.close} => ${SideNavAnimationStatesEnum.open}`, [
        animate(
          '400ms',
          keyframes([
            style({ width: '150px', opacity: 0 }),
            style({ width: '250px', opacity: 0.5 }),
            style({ width: '350px', opacity: 1 }),
          ])
        ),
      ]),
      transition(`${SideNavAnimationStatesEnum.open} => ${SideNavAnimationStatesEnum.close}`, [
        animate(
          '400ms',
          keyframes([
            style({ width: '250px', opacity: 0 }),
            style({ width: '150px', opacity: 0.5 }),
            style({ width: '70px', opacity: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class SideNavComponent implements OnInit, OnDestroy {
  @Input() isOpen = false;
  @Output() openCloseEvent = new EventEmitter<boolean>();

  openCloseBtnId = SideNavIdsEnum.openCloseBtn;
  openCloseNavBar = SideNavAnimationStatesEnum.close;
  subs$ = new SubCollection();

  constructor(private sideNavHandler: SideNavHandler) {}

  ngOnInit(): void {
    this.getSidenavOpenSection();
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }

  getSidenavOpenSection() {
    this.subs$.add = this.sideNavHandler.getSidenavOpenSection$.pipe(take(1)).subscribe((sidenavOpenSection) => {
      this.isOpen = !!sidenavOpenSection;
      this.openCloseNavBar = this.isOpen ? SideNavAnimationStatesEnum.open : SideNavAnimationStatesEnum.close;
    });
  }

  openCloseAction(): void {
    this.isOpen = !this.isOpen;
    this.openCloseEvent.emit(this.isOpen);
    this.openCloseNavBar =
      this.openCloseNavBar === SideNavAnimationStatesEnum.open
        ? SideNavAnimationStatesEnum.close
        : SideNavAnimationStatesEnum.open;
  }
}
