<section class="header">
  <h2 *ngFor="let content of titles" class="header__item">
    {{ content.title }}
    <button *ngIf="content.tooltip" (click)="openTooltipDialog(content.tooltip)">
      <xpo-icon iconName="info"></xpo-icon>
    </button>
    <ng-content></ng-content>
  </h2>
  <section *ngIf="buttons.length" class="header__right-section">
    <ng-container *ngFor="let button of buttons">
      <button
        xpoButton
        [matTooltip]="button.tooltipText"
        [matTooltipPosition]="button.tooltipPosition"
        (click)="button.action()"
      >
        <xpo-icon [iconName]="button.iconName"></xpo-icon>
      </button>
    </ng-container>
  </section>
</section>
