import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'negativeNumberToParentheses',
})
export class NegativeNumberToParenthesesPipe implements PipeTransform {
  transform(value: number | string): string {
    if (value == null) {
      return '';
    }

    const valueAsString = `${value}`;
    return valueAsString.charAt(0) === '-' ? `(${valueAsString.substring(1, valueAsString.length)})` : valueAsString;
  }
}
