import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CorrectionsCustomerModel } from '@shared/models/corrections/corrections-form/customer/customer-form.model';
import { CustomerMatch } from '@xpo-ltl-2.0/sdk-customer';
import { RatingCustomerSearchFields, RatingCustomerSearchFormNameEnum } from '@xpo-ltl/rating';

@Component({
  selector: 'app-customer-search-dialog',
  templateUrl: './customer-search-dialog.component.html',
  styleUrls: ['./customer-search-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerSearchDialogComponent {

  @Input() getCustomerBtnId = 'customerSearchGetBtn';
  @Input() cancelBtnId = 'customerSearchCancelBtn';
  @Input() ratingCustomerSearchFields: RatingCustomerSearchFields | undefined;
  @Input() customerDisableFields: RatingCustomerSearchFormNameEnum[] | undefined;
  @Output() selectedCustomer$ = new EventEmitter<CorrectionsCustomerModel>();

  selectedCustomer: CustomerMatch;

  constructor(public dialogRef: MatDialogRef<CustomerSearchDialogComponent>) { }

  close(): void {
    this.dialogRef.close();
  }

  getCustomer(): void {
    const selectedCustomer = this.getCustomerValue(this.selectedCustomer);
    this.selectedCustomer$.emit(selectedCustomer);
    this.close();
  }

  getCustomerValue(selectedCustomer: CustomerMatch): CorrectionsCustomerModel {
    return {
      customerNumber: selectedCustomer.customerNbr,
      madCode: !this.emptyCustomerField(selectedCustomer.madCd) ? selectedCustomer.madCd : '',
      nameOne: !this.emptyCustomerField(selectedCustomer.name1) ? selectedCustomer.name1 : '',
      nameTwo: !this.emptyCustomerField(selectedCustomer.name2) ? selectedCustomer.name2 : '',
      address: !this.emptyCustomerField(selectedCustomer.addressLine1) ? selectedCustomer.addressLine1 : '',
      city: !this.emptyCustomerField(selectedCustomer.cityName) ? selectedCustomer.cityName : '',
      state: !this.emptyCustomerField(selectedCustomer.stateCd) ? selectedCustomer.stateCd : '',
      zipCode: !this.emptyCustomerField(selectedCustomer.postalCd) ? selectedCustomer.postalCd : '',
      zipCodeComplementation: !this.emptyCustomerField(selectedCustomer.usZip4) ? selectedCustomer.usZip4 : '',
      country: !this.emptyCustomerField(selectedCustomer.countryCd) ? selectedCustomer.countryCd : '',
    };
  }

  selectCustomer(selectedCustomer: CustomerMatch): void {
    this.selectedCustomer = selectedCustomer;
  }

  disableGetCustomerBtn(): boolean {
    return !this.selectedCustomer;
  }

  emptyCustomerField(value: string): boolean {
    return value == null || value === 'null';
  }

}
