import { Pipe, PipeTransform } from '@angular/core';
import { OracleStatusDisplayEnum, OracleStatusEnum } from '@shared/enums/common-data/oracle-status.enum';

@Pipe({
  name: 'oracleStatus'
})
export class OracleStatusPipe implements PipeTransform {

  transform(oracleStatus: OracleStatusEnum): string {
    switch (oracleStatus) {
      case OracleStatusEnum.open:
        return OracleStatusDisplayEnum.open;
      case OracleStatusEnum.close:
        return OracleStatusDisplayEnum.close;
      default:
        return '';
    }
  }

}
