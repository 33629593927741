<mat-form-field floatLabel="never" class="input">
  <input
    #inputField
    appInputTextFormat
    matInput
    class="input"
    type="search"
    [id]="id"
    [name]="name"
    [placeholder]="placeholder"
    [formControl]="formField"
    (keyup.enter)="enterKey.emit()"
    (blur)="blurAction.emit()"
  />
  <button mat-icon-button class="icon-btn" [id]="iconSearchId">
    <xpo-icon iconName="search" (click)="onSearch.emit()"></xpo-icon>
  </button>
</mat-form-field>
