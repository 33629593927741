import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SelectFieldErrorsService {

  validateFormField(formField: FormControl, options: { value: any }[]): void {
    if (formField?.value != null && options?.length > 0) {
      const isValue = options.some(value => value.value === formField.value);
      if (!isValue) {
        formField.setValue(null);
        formField.markAsTouched();
        console.warn('The formField value doesnt match to list', formField.value, options);
      }
    }
  }
}
