import { Injectable } from '@angular/core';
import { CountryStatesModel } from '@shared/models/shared/common-data/country-states.model';
import { ElementSelectOptionModel } from '@shared/models/shared/select-option.model';
import { RatingCountryCdEnum, ratingStatesData, RatingStatesModel } from '@xpo-ltl/rating';

@Injectable({
  providedIn: 'root'
})
export class CountryStatesDataService {

  constructor() { }

  getCountryStates(): CountryStatesModel {
    return ratingStatesData;
  }

  getAllStatesList(): RatingStatesModel[] {
    const states = this.getCountryStates();
    return [...states[RatingCountryCdEnum.us].states, ...states[RatingCountryCdEnum.ca].states, ...states[RatingCountryCdEnum.mx].states];
  }

  getAllStatesForOptionElement(countryStates: CountryStatesModel): ElementSelectOptionModel[] {
    let allStates: RatingStatesModel[] = [];

    Object.values(countryStates).forEach(country => {
      allStates = [...allStates, ...country.states];
    });

    return allStates.map(state => ({
      viewValue: state.name,
      value: state.abbreviation
    }));
  }

  getStatesByCountryForOptionElement(countryStates: CountryStatesModel, countryCd: RatingCountryCdEnum): ElementSelectOptionModel[] {
    const statesByCountry: RatingStatesModel[] = countryCd === RatingCountryCdEnum.cn ? countryStates[RatingCountryCdEnum.ca].states : countryStates[countryCd].states;
    return statesByCountry.map(state => ({
      viewValue: state.name,
      value: state.abbreviation
    }));
  }
}
