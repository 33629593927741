import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { yesNoOptionArrayValue } from '@shared/data-values/shared/boolean.data';
import { ElementSelectOptionModel } from '@shared/models/shared/select-option.model';

@Component({
  selector: 'app-yes-no-radio-button',
  templateUrl: './yes-no-radio-button.component.html',
  styleUrls: ['./yes-no-radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YesNoRadioButtonComponent {
  @Input() formField: FormControl;
  @Input() id = 'yesNoRadioBtn';
  @Input() name = 'yesNoRadioBtn';
  @Input() readonly = false;

  yesNoOptions: ElementSelectOptionModel[] = yesNoOptionArrayValue;

  constructor() { }

}
